<template>
  <div>
    <div id="component-breadcrumbs">
      <b-breadcrumb
        class="breadcrumb-slash"
        :items="breadcrumbItems"
      />
    </div>
    <h2>{{ getCase.FileNumber }}</h2>
    <hr>
    <b-row>
      <b-col md="9">
        <h2 v-if="ApprovedByManagerID">
          Unapprove Action
        </h2>
        <h2 v-else>
          Approve Action
        </h2>
        <div class="white-bg-container">
          <b-row>
            <b-col md="5">
              <b-form-group
                label="Action Type"
                label-for="actionType"
              >
                <b-form-input
                  id="actionType"
                  v-model="ActionType"
                  placeholder=""
                  name="actionType"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="7">
              <b-form-group
                label="Description"
                label-for="description"
              >
                <div
                  class="collapse-textarea"
                  :class="{expanded: !isExpandedDescription}"
                >
                  <textarea
                    v-model="Description"
                    class="expandable-textarea"
                    :class="[isExpandedDescription ? 'not-expanded' : 'expanded']"
                    contentEditable
                    :readonly="readOnly"
                  />
                  <div @click="isExpandedDescription = !isExpandedDescription">
                    <span
                      class="expand-arrow mt-n1"
                      :class="[isExpandedDescription ? 'not-expanded' : 'expanded']"
                    >&#187;</span>
                  </div>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="4">
              <b-form-group
                label="Time Window"
                label-for="timeWindow"
              >
                <b-form-input
                  id="timeWindow"
                  v-model="TimeWindow"
                  placeholder=""
                  name="timeWindow"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Scheduled Date"
                label-for="scheduledDate"
              >
                <b-form-input
                  id="scheduledDate"
                  v-model="ScheduledDate"
                  placeholder=""
                  name="scheduledDate"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label="Daily Instructions"
                label-for="dailyInstructions"
              >
                <div
                  class="collapse-textarea"
                  :class="{expanded: !isExpandedDailyInstructions}"
                >
                  <textarea
                    v-model="DailyInstructions"
                    class="expandable-textarea"
                    :class="[isExpandedDailyInstructions ? 'not-expanded' : 'expanded']"
                    contentEditable
                    :readonly="readOnly"
                  />
                  <div @click="isExpandedDailyInstructions = !isExpandedDailyInstructions">
                    <span
                      class="expand-arrow mt-n1"
                      :class="[isExpandedDailyInstructions ? 'not-expanded' : 'expanded']"
                    >&#187;</span>
                  </div>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
    <b-row class="pt-5">
      <b-col>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          class="mr-2"
          type="reset"
          variant="danger"
          @click="resetForm()"
        >
          Cancel
        </b-button>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="submit"
          variant="primary"
          @click="approveAction()"
        >
          <span v-if="ApprovedByManagerID">Unapprove Action</span>
          <span v-else>Approve Action</span>
        </b-button>
      </b-col>
    </b-row>

    <b-modal
      v-model="modalShow"
      hide-backdrop
      size="md"
      centered
    >
      <template #modal-header="">
        <div class="d-flex mt-1 mb-n3 text-center flex-column justify-content-center">
          <h2 class="text-center">
            <strong>
              Are you sure you want to
              <span v-if="ApprovedByManagerID">unapprove</span>
              <span v-else>approve</span>
              this action?
            </strong>
          </h2>
        </div>
      </template>

      <template #modal-footer="">
        <div class="d-flex mb-1 justify-content-center flex-grow-1">
          <div class="w-50 d-flex justify-content-center">
            <b-button
              type="reset"
              variant="outline-secondary"
              size="md"
              @click="chancelActionApprove()"
            >
              Cancel
            </b-button>
          </div>
          <div class="w-50 d-flex justify-content-center">
            <b-button
              type="submit"
              variant="primary"
              class="mr-1"
              size="md"
              @click="actionApprove()"
            >
              <span v-if="ApprovedByManagerID">Unapprove</span>
              <span v-else>Approve</span>
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {BBreadcrumb, BButton, BCol, BFormGroup, BFormInput, BRow} from "bootstrap-vue";
import APIService from "@core/utils/APIService";
import {mapGetters} from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import Ripple from "vue-ripple-directive";

const apiService = new APIService();

export default {
  components: {
    BBreadcrumb,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      CaseID: "",
      ActionID: "",
      ActionType: "",
      Description: "",
      TimeWindow: "",
      ScheduledDate: "",
      DailyInstructions: "",
      ApprovedByManagerID: "",

      modalShow: false,
      readOnly: true,
      isExpandedDescription: false,
      isExpandedDailyInstructions: false,
    }
  },
  computed: {
    ...mapGetters({
      getCase: "cases/getCase",
    }),
    breadcrumbItems: function() {
      return [
        {
          text: 'Cases',
          href: '/cases'
        },
        {
          text: this.getCase.FileNumber,
        },
      ]
    },
  },
  watch: {},
  async created() {
    try {
      this.ActionID = this.$route.params.actionID;
      if (this.ActionID) {
        apiService
            .get("action/" + this.ActionID + "/detail")
            .then(res => {
              const options = { hour: '2-digit', minute: '2-digit', second: '2-digit' };
              let TimeWindowStart = new Date(res.data.TimeWindowStart).toLocaleTimeString("en-US", options);
              let TimeWindowEnd = new Date(res.data.TimeWindowEnd).toLocaleTimeString("en-US", options);
              if (TimeWindowStart.indexOf('Invalid') !== -1) {
                TimeWindowStart = this.tConvert(res.data.TimeWindowStart)
                TimeWindowEnd = this.tConvert(res.data.TimeWindowEnd)
              }
              this.TimeWindow = TimeWindowStart + " - " + TimeWindowEnd;
              this.CaseID = res.data.CaseID;
              this.ActionType = res.data.ActionTypeName;
              this.Description = res.data.Title;
              this.ScheduledDate = this.dateFormat(res.data.ScheduledDate);
              this.DailyInstructions = res.data.DailyInstructions;
              this.ApprovedByManagerID = res.data.ApprovedByManagerID;
            })
      }
    } catch (err) {
      this.error = err;
    }
  },
  methods: {
    showToast(variant, position, timeout, data) {
      this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notification',
              icon: 'InfoIcon',
              text: data,
              variant,
            },
          },
          {
            position,
            timeout,
          })
    },
    tConvert(time) {
      time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
      if (time.length > 1) {
        time = time.slice(1);
        time[5] = +time[0] < 12 ? 'AM' : 'PM';
        time[0] = +time[0] % 12 || 12;
      }
      return time.join(' ');
    },
    dateFormat(val) {
      const date = new Date(val);
      const options = {year: 'numeric', month: '2-digit', day: '2-digit'}
      if (date) return date.toLocaleDateString("en-US", options);
    },
    resetForm() {
      this.$router.push('/cases/' + this.CaseID + '/detail/ACTIONS');
    },
    approveAction() {
      this.modalShow = true;
    },
    chancelActionApprove() {
      this.modalShow = false;
    },
    actionApprove() {
      const postData = {
        "CaseID": this.CaseID,
        "ActionID": this.ActionID
      }
      const endpoint = this.ApprovedByManagerID ? "action/unapprove" : "action/approve";
      this.modalShow = false;
      apiService
          .post(endpoint, postData)
          .then((res) => {
            if (res) {
              this.showToast('success', 'top-center', 4000, 'Action ' + (this.ApprovedByManagerID ? "unapproved" : "approved" + ' !'));
              this.$router.push('/cases/' + this.CaseID + '/detail/ACTIONS');
            }
          })
    }
  }
}
</script>

<style scoped>

</style>